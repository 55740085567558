<nav class="navbar navbar-expand-xl nav-background sticky-top" [ngClass]="{'scrolled': siteScrolled}" style="transition: all 0.6s ease;" *ngIf="!loginDisplay">
  <div class="container-fluid mx-5 py-2">
    <a class="navbar-brand" [routerLink]="['/']">
      <img loading="lazy" src="../../../../assets/images/logo-white.png" alt="Nati" width="180" style="transition: all 0.6s ease;">
    </a>

    <button class="navbar-toggler navbar-light" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto mb-2 mb-md-0 md-collapse d-flex gap-4">
        <li class="nav-item">
          <a>
            <app-switchlang></app-switchlang>
          </a>
        </li>
        <li class="nav-item">
          <a>
            <button type="button" class="btn loginBtn fw-bold" (click)="login()">{{'navbar.botonLogin' | translate}}</button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

        <!-- <li class="nav-item">
          <a>
            Precios
          </a>
        </li>
        <li class="nav-item">
          <a>
            Aplicaciones
          </a>
        </li>
        <li class="nav-item">
          <a>
            Funcionalidades
          </a>
        </li>
        <li class="nav-item">
          <a>
            Sobre nosotros
          </a>
        </li>
        <li class="nav-item">
          <a>
            Blog
          </a>
        </li> -->

<nav class="navbar navbar-expand-sm logged" *ngIf="loginDisplay">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/']">
      <img loading="lazy" src="../../../../assets/images/logo.png" alt="Nati" height="60px">
    </a>

    <div id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto mb-2 mb-md-0 d-flex gap-2 flex-row align-items-center">
        <li class="nav-item" id="notifications-navbar">
          <a>
            <button type="button" class="btn fw-bold position-relative" (click)="toggleNotificaciones()">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="black"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"/></svg>
              <span class="notification-badge" *ngIf="enviarNotificaciones.data.length > 0">{{ enviarNotificaciones.data.length }}</span>
            </button>
          </a>
        </li>
        <app-notificaciones [mostrarNotificaciones]="mostrarNotificaciones" [tenant]="tenant_name" [enviarNotificaciones]="enviarNotificaciones"></app-notificaciones>

        <li class="nav-item d-none d-md-block" id="language-navbar">
          <app-switchlang></app-switchlang>
        </li>

        <li class="nav-item" id="profile-navbar">
          <a>
            <div>
              <label for="profile" class="profile-dropdown">
                <input type="checkbox" id="profile">
                <span>
                  <span class="fs-5">
                    {{nickname | titlecase}}
                  </span>
                  <br>
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"/></svg>

                <ul class="shadow-sm">
                  <li>
                    <ng-container *ngIf="currentUrl.includes('/dashboard')">
                      <a [routerLink]="['/']">
                        <svg width="24" height="24" viewBox="0 0 513 513" fill="black" xmlns="http://www.w3.org/2000/svg">
                          <path d="M80.8281 212.984V448.984C80.8281 453.228 82.5138 457.298 85.5144 460.298C88.515 463.299 92.5847 464.984 96.8281 464.984H192.828V328.984C192.828 322.619 195.357 316.515 199.858 312.014C204.358 307.513 210.463 304.984 216.828 304.984H296.828C303.193 304.984 309.298 307.513 313.799 312.014C318.3 316.515 320.828 322.619 320.828 328.984V464.984H416.828C421.072 464.984 425.141 463.299 428.142 460.298C431.142 457.298 432.828 453.228 432.828 448.984V212.984" stroke="white" stroke-width="32" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M480.828 256.983L267.718 52.9825C262.718 47.7025 251.028 47.6425 245.938 52.9825L32.8281 256.983M400.828 179.983V64.9825H352.828V133.983" stroke="black" stroke-width="32" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          {{'navbar.irNati' | translate}}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!currentUrl.includes('/dashboard')">
                      <a [routerLink]="['/dashboard']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black"><path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path><path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path></svg>
                      {{'navbar.panel' | translate}}
                      </a>
                    </ng-container>
                  </li>
                  <li (click)="logout()" style="border-top: solid 2px #ccc">
                    <a>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black"><path d="m2 12 5 4v-3h9v-2H7V8z"></path><path d="M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z"></path></svg>
                      {{'navbar.cerrarSesion' | translate}}
                    </a>
                  </li>
                </ul>
              </label>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
