<section>
  <div class="nati-sec-1">
    <div class="nati-content">
      <div class="d-none d-xxl-flex flex-nowrap justify-content-center align-items-center xxl-sec">
        <img src="../../../assets/images/landing/avatar1-sec1.webp" alt="Avatar mujer Nati" class="img-fluid mt-auto" data-aos="fade-up" data-aos-duration="3000">

        <div class="pb-5" data-aos="fade-down" data-aos-duration="3000">
          <h1>
            {{'contenidoMain.nati-sec-1.titulo' | translate}}
            <br>
            <span>{{'contenidoMain.nati-sec-1.titulo-cont' | translate}}</span>
          </h1>

          <p class="py-3">{{'contenidoMain.nati-sec-1.texto' | translate}}</p>

          <button class="button-landing shadow-sm" (click)="openModalPlanFree(templatePlansFree, 'prueba-gratis', 'web')">{{'contenidoMain.nati-sec-1.boton' | translate}}</button>
        </div>

        <img src="../../../assets/images/landing/avatar2-sec1.webp" alt="Avatar hombre Nati" class="img-fluid mt-auto" data-aos="fade-up" data-aos-duration="3000"> 
      </div>

      <div class="d-flex d-xxl-none flex-wrap justify-content-center align-items-center px-5 px-lg-2">
        <div class="pb-5" data-aos="fade-down" data-aos-duration="3000">
          <h1>
            {{'contenidoMain.nati-sec-1.titulo' | translate}}
            <br>
            <span>{{'contenidoMain.nati-sec-1.titulo-cont' | translate}}</span>
          </h1>

          <p class="py-3">{{'contenidoMain.nati-sec-1.texto' | translate}}</p>

          <button class="button-landing shadow-sm" (click)="openModalPlanFree(templatePlansFree, 'prueba-gratis', 'web')">{{'contenidoMain.nati-sec-1.boton' | translate}}</button>
        </div>

        <div class="d-flex flex-nowrap gap-3" data-aos="fade-up" data-aos-duration="3000">
          <img src="../../../assets/images/landing/avatar1-sec1.webp" alt="Avatar mujer Nati" class="img-fluid d-none d-lg-block">
          <img src="../../../assets/images/landing/avatar2-sec1.webp" alt="Avatar hombre Nati" class="img-fluid"> 
        </div>
      </div>
    </div>

    <div class="triangle"></div>
    <div class="triangle-border"></div>
  </div>

  <div class="nati-sec-2">
    <div class="nati-content text-center">
      <div class="px-0 px-md-5">
        <h2 class="mt-5 mt-md-4">{{'contenidoMain.section4.titulo' | translate}}</h2>
        <p class="mb-5">{{'contenidoMain.section4.texto' | translate}}</p>
      </div>

      <div class="mt-5">
        <p-carousel [value]="carouselUseCases" [numVisible]="4" [numScroll]="1" [circular]="false"
          [responsiveOptions]="responsiveOptions" [showNavigators]="true" [showIndicators]="true">
          <ng-template let-carouselBlog pTemplate="item">
            <div class="card carousel-usecases shadow-sm position-relative">
              <img src="{{carouselBlog.imagen}}" class="card-img-top" alt="Blog">
              <div class="title-usecase">
                <h5>{{carouselBlog.title}}</h5>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>

  <div class="gradient-section">
    <div class="nati-sec-3">
      <div class="nati-content d-flex flex-wrap justify-content-center align-items-center text-center">
        <h2 class="fw-bold" data-aos="zoom-in" data-aos-duration="2000">{{'contenidoMain.nati-sec-3.titulo' | translate}}</h2>
        <p data-aos="zoom-in" data-aos-duration="2000">{{'contenidoMain.nati-sec-3.texto' | translate}}</p>

        <div class="container-img shadow-sm" data-aos="fade-down" data-aos-duration="3000">
          <img src="../../../assets/images/landing/nati-sec3.webp" class="img-fluid" alt="Interfaz Nati">
        </div>

        <div class="circle-sec-3"></div>
      </div>
    </div>

    <div class="nati-sec-4">
      <div class="nati-content container-fluid d-flex justify-content-center">
        <div class="row">
          <div class="col-xl d-flex flex-column justify-content-center align-items-start" data-aos="fade-left" data-aos-duration="3000">
            <h2>{{'contenidoMain.nati-sec-4.titulo' | translate}}</h2>
            <p>{{'contenidoMain.nati-sec-4.texto' | translate}}</p>
            <button class="button-landing shadow-sm mt-2 mt-lg-5" onclick="window.location.href='mailto:sales@nati.ai'">{{'contenidoMain.nati-sec-4.boton' | translate}}</button>
          </div>

          <div class="col-xl d-flex flex-column justify-content-center align-items-center" data-aos="fade-right" data-aos-duration="3000">
            <img src="../../../assets/images/landing/avatar1-sec4.webp" class="img-fluid" alt="Avatar mujer Nati">
          </div>
        </div>
      </div>
    </div>

    <div class="nati-sec-5">
      <div class="nati-content container-fluid text-center">
        <h2>{{'contenidoMain.nati-sec-5.titulo' | translate}}</h2>
        <p>{{'contenidoMain.nati-sec-5.texto' | translate}}</p>

        <div class="row row-cols-1 row-cols-lg-3 g-3 g-lg-5 px-5 container-fluid">
          <div class="col" data-aos="fade-down" data-aos-duration="3000">
            <div class="container-img-sec5 mb-4">
              <img src="../../../assets/images/landing/img1-sec5.webp" class="img-fluid" alt="Interfaz Nati 1">
            </div>

            <h3>{{'contenidoMain.nati-sec-5.divs.div1.titulo' | translate}}</h3>
            <p>{{'contenidoMain.nati-sec-5.divs.div1.texto' | translate}}</p>
          </div>
          
          <div class="col" data-aos="fade-up" data-aos-duration="3000">
            <div class="container-img-sec5 mb-4">
              <img src="../../../assets/images/landing/img2-sec5.webp" class="img-fluid" alt="Interfaz Nati 2">
            </div>

            <h3>{{'contenidoMain.nati-sec-5.divs.div2.titulo' | translate}}</h3>
            <p>{{'contenidoMain.nati-sec-5.divs.div2.texto' | translate}}</p>
          </div>
          
          <div class="col" data-aos="fade-down" data-aos-duration="3000">
            <div class="container-img-sec5 mb-4">
              <img src="../../../assets/images/landing/img3-sec5.webp" class="img-fluid" alt="Interfaz Nati 3">
            </div>

            <h3>{{'contenidoMain.nati-sec-5.divs.div3.titulo' | translate}}</h3>
            <p>{{'contenidoMain.nati-sec-5.divs.div3.texto' | translate}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="nati-sec-6">
      <div class="nati-content container-fluid">
        <div class="row">
          <div class="col-lg d-flex flex-column justify-content-center align-items-center gap-3 pt-5 pt-lg-0" data-aos="zoom-in-right" data-aos-duration="2000">
            <p>{{'contenidoMain.nati-sec-6.titulo' | translate}}</p>
            <button class="button-landing shadow-sm" (click)="openModalPlanFree(templatePlansFree, 'prueba-gratis', 'web')">{{'contenidoMain.nati-sec-6.boton' | translate}}</button>
            <span class="mt-2">{{'contenidoMain.nati-sec-6.texto' | translate}}</span>
          </div>
          
          <div class="col-lg p-0 order-first order-lg-last">
            <img src="../../../assets/images/landing/img1-sec6.webp" class="img-fluid float-end" alt="Nati en Tablet">
          </div>
        </div>

        <div class="circle-sec-6"></div>
      </div>
    </div>

    <!-- TO DO - Sección preguntas -->
    <!-- <div class="nati-sec-7">
      <div class="nati-content d-flex flex-column justify-content-center align-items-center">
        <h2 class="fw-bold">¿Preguntas sobre tu nuevo asistente digital?</h2>

        <div class="accordion-wrapper mt-5">
          <div class="accordion">
            <input type="radio" name="radio-a" id="check1" checked>
            <label class="accordion-label" for="check1">¿Cómo se entrena Nati con la información de mi empresa?</label>
            <div class="accordion-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at justo non felis tincidunt fermentum a hendrerit nibh. Curabitur eget pretium urna. Donec venenatis ligula in scelerisque auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vivamus in ultrices augue. Nullam eu eros quam. Ut tempor rutrum nisi, at cursus tortor aliquam sed.</p>
            </div>
          </div>
          <div class="accordion">
            <input type="radio" name="radio-a" id="check2">
            <label class="accordion-label" for="check2">¿Nati puede ser utilizado para tareas internas?</label>
            <div class="accordion-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at justo non felis tincidunt fermentum a hendrerit nibh. Curabitur eget pretium urna. Donec venenatis ligula in scelerisque auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vivamus in ultrices augue. Nullam eu eros quam. Ut tempor rutrum nisi, at cursus tortor aliquam sed.</p>
            </div>
          </div>
          <div class="accordion">
            <input type="radio" name="radio-a" id="check3">
            <label class="accordion-label" for="check3">¿Los avatares humanos son personalizables?</label>
            <div class="accordion-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at justo non felis tincidunt fermentum a hendrerit nibh. Curabitur eget pretium urna. Donec venenatis ligula in scelerisque auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vivamus in ultrices augue. Nullam eu eros quam. Ut tempor rutrum nisi, at cursus tortor aliquam sed.</p>
            </div>
          </div>
          <div class="accordion">
            <input type="radio" name="radio-a" id="check4">
            <label class="accordion-label" for="check4">¿Qué pasa cuando termina la prueba gratuita?</label>
            <div class="accordion-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at justo non felis tincidunt fermentum a hendrerit nibh. Curabitur eget pretium urna. Donec venenatis ligula in scelerisque auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vivamus in ultrices augue. Nullam eu eros quam. Ut tempor rutrum nisi, at cursus tortor aliquam sed.</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="nati-sec-8">
      <div class="nati-content">
        <div class="row pb-5 py-md-5 container-fluid">
          <div class="col-lg m-auto px-5 text-center justify-content-center d-grid">
            <h2 class="mt-5 mt-md-4">{{'contenidoMain.sectionDisponible.titulo' | translate}}</h2>
            <p class="mb-4 my-md-4">{{'contenidoMain.sectionDisponible.texto' | translate}}</p>
  
            <div class="d-flex flex-wrap gap-4 justify-content-center">
              <button class="shadow-sm button-landing"
                (click)="showSection('web')">{{'contenidoMain.sectionDisponible.web' | translate}}</button>
              <button class="shadow-sm button-landing"
                (click)="showSection('kiosco')">{{'contenidoMain.sectionDisponible.kiosco' | translate}}</button>
              <button class="shadow-sm button-landing"
                (click)="showSection('whatsapp')">{{'contenidoMain.sectionDisponible.whatsapp' | translate}}</button>
            </div>
          </div>
        </div>
  
        <div class="container-fluid section-plans" id="section-plans">
          <!-- Modal plan seleccionado gratis -->
          <ng-template #templatePlansFree>
            <div class="modal-body" style="display: grid;">
              <button type="button" class="btn-close close pull-right"
                style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlans?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
              <div class="register-container">
                <div class="row m-0 row-container">
                  <div class="col d-block m-auto d-none d-xl-block">
                    <div class="w-100 bg-nati-avatar">
                      <img src="../../../assets/images/logo.png" class="position-absolute top-0 m-3" alt="Nati"
                        width="155">
      
                      <div class="text-center">
                        <img src="../../../assets/images/landing/nati.webp" alt="Nati avatar" class="img-fluid avatar">
                      </div>
      
                      <div class="blur-container">
                        <div class="blur-text">
                          <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                              translate}} </b></h5>
                          <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}} </p>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="col">
                    <div class="w-100 d-block m-auto text-center">
                      <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalPruebaGratis.titulo' | translate}}</h4>
      
                      <hr class="line-unavailable">
      
                      <p class="service-unavailable-text">{{'contenidoMain.modales.modalPruebaGratis.texto1' | translate}}
                        <br> {{'contenidoMain.modales.modalPruebaGratis.texto2' | translate}}<br> </p>
      
                      <hr class="line-unavailable">
      
                      <button class="button-primary shadow-sm mobile-button"
                        (click)="registerWithPlan(canalSelected, planSelected, 'semanal')">{{'contenidoMain.modales.registrarse'
                        | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
      
          <!-- Modal plan seleccionado de pago -->
          <ng-template #templatePlans>
            <div class="modal-body" style="display: grid;">
              <button type="button" class="btn-close close pull-right"
                style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlans?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
              <div class="register-container">
                <div class="row m-0 row-container">
                  <div class="col d-block m-auto d-none d-xl-block">
                    <div class="w-100 bg-nati-avatar">
                      <img src="../../../assets/images/logo.png" class="position-absolute top-0 m-3" alt="Nati"
                        width="155">
      
                      <div class="text-center">
                        <img src="../../../assets/images/landing/nati.webp" alt="Nati avatar" class="img-fluid avatar">
                      </div>
      
                      <div class="blur-container">
                        <div class="blur-text">
                          <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                              translate}}</b></h5>
                          <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="col">
                    <div class="w-100 d-block m-auto text-center">
                      <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalAdquirirPlan.adquirir' |
                        translate}}{{planSelectedFormatted | lowercase}} /
                        {{'contenidoMain.modales.modalAdquirirPlan.canal' | translate}}
                        {{canalSelected}}</h4>
      
                      <hr class="line-unavailable">
      
                      <p class="service-unavailable-text">{{'contenidoMain.modales.modalAdquirirPlan.texto' | translate}}
                        <b>{{planSelectedFormatted | lowercase}}</b>
                        {{'contenidoMain.modales.modalAdquirirPlan.texto2' | translate}}</p>
      
                      <hr class="line-unavailable">
      
                      <button class="button-primary shadow-sm mobile-button"
                        (click)="registerWithPlan(canalSelected, planSelected, frecuenciaSelected)">{{'contenidoMain.modales.registrarse'
                        | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
      
          <!-- Modal plan seleccionado de pago anual -->
          <ng-template #templatePlanAnual>
            <div class="modal-body" style="display: grid;">
              <button type="button" class="btn-close close pull-right"
                style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlanAnual?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
              <div class="register-container">
                <div class="row m-0 row-container">
                  <div class="col d-block m-auto d-none d-xl-block">
                    <div class="w-100 bg-nati-avatar">
                      <img src="../../../assets/images/logo.png" class="position-absolute top-0 m-3" alt="Nati"
                        width="155">
      
                      <div class="text-center">
                        <img src="../../../assets/images/landing/nati.webp" alt="Nati avatar" class="img-fluid avatar">
                      </div>
      
                      <div class="blur-container">
                        <div class="blur-text">
                          <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                              translate}} </b></h5>
                          <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="col">
                    <div class="w-100 d-block m-auto text-center">
                      <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalAdquirirPlan.adquirir' | translate}}
                        {{planSelectedAnualFormatted | lowercase}} {{'contenidoMain.modales.modalAdquirirPlan.formaAnual' |
                        translate}} {{canalSelectedAnual}}</h4>
      
                      <hr class="line-unavailable">
      
                      <p class="service-unavailable-text">{{'contenidoMain.modales.modalAdquirirPlan.texto' |
                        translate}}<b>{{'contenidoMain.modales.modalAdquirirPlan.plan' | translate}}
                          {{planSelectedAnualFormatted | lowercase}}
                          {{'contenidoMain.modales.modalAdquirirPlan.anual' | translate}}</b>
                        {{'contenidoMain.modales.modalAdquirirPlan.texto2' | translate}}</p>
      
                      <hr class="line-unavailable">
      
                      <button class="button-primary shadow-sm mobile-button"
                        (click)="registerWithPlan(canalSelectedAnual, planSelectedAnual, frecuenciaSelectedAnual)">{{'contenidoMain.modales.registrarse'
                        | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
      
          <div class="d-block m-auto my-5 pb-5" *ngIf="showSectionWeb || showSectionKiosco || showSectionWhatsapp">
      
            <div class="w-100 d-block m-auto text-center">
              <!-- Planes canal Web -->
              <div *ngIf="showSectionWeb">
                <h2 class="fw-bold mt-5 mb-3 mt-md-4 text-center">{{'contenidoMain.sectionPlanes.tituloWeb' |
                  translate}}</h2>
                <p class="mb-4">{{'contenidoMain.sectionPlanes.textoWeb' | translate}}</p>
      
                <div class="w-100 d-block m-auto text-center">
                  <div id="tabview-frequency-suscription" class="">
                    <p-tabView>
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebMensual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWebMensual[0]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebMensual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlans(templatePlans, 'basico', 'web', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebMensual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWebMensual[1]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebMensual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlans(templatePlans, 'estandar', 'web', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebMensual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWebMensual[2]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebMensual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlans(templatePlans, 'premium', 'web', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebAnual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualBasicoWeb}}
                                </p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebAnual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'web', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebAnual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualEstandarWeb}}
                                </p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebAnual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'web', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWebAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWebAnual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualPremiumWeb}}
                                </p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWebAnual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'web', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                </div>
              </div>
              <!-- Planes canal Web -->
      
              <!-- Planes canal Kiosco -->
              <div *ngIf="showSectionKiosco">
                <h2 class="mt-5 mb-4 mt-md-4 text-center">{{'contenidoMain.sectionPlanes.tituloKiosco' |
                  translate}}</h2>
                <p class="mb-4">{{'contenidoMain.sectionPlanes.textoKiosco' | translate}}</p>
      
                <div class="w-100 d-block m-auto text-center">
                  <div id="tabview-frequency-suscription" class="">
                    <p-tabView>
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoMensual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $242</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}} </p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoMensual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlans(templatePlans, 'basico', 'kiosco', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoMensual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $206</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoMensual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlans(templatePlans, 'estandar', 'kiosco', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoMensual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $181</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoMensual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlans(templatePlans, 'premium', 'kiosco', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoAnual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $217,8</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoAnual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'kiosco', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoAnual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $185,4</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoAnual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'kiosco', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalKioscoAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalKioscoAnual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $162,9</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalKioscoAnual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'kiosco', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                </div>
              </div>
              <!-- Planes canal Kiosco -->
      
              <!-- Planes canal Whatsapp-->
              <div *ngIf="showSectionWhatsapp">
                <h2 class="mt-5 mb-4 mt-md-4 text-center">{{'contenidoMain.sectionPlanes.tituloWhatsapp' |
                  translate}}</h2>
                <p class="mb-4">{{'contenidoMain.sectionPlanes.textoWhatsapp' | translate}}</p>
      
                <div class="w-100 d-block m-auto text-center">
                  <div id="tabview-frequency-suscription">
                    <p-tabView>
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappMensual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWhatsappMensual[0]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappMensual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlans(templatePlans, 'basico', 'whatsapp', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappMensual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWhatsappMensual[1]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappMensual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlans(templatePlans, 'estandar', 'whatsapp', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappMensual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                                  ${{planesCanalWhatsappMensual[2]?.precioUnidadAdicional}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappMensual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlans(templatePlans, 'premium', 'whatsapp', 'mensual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
      
                      <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                        <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                          <!-- Plan Basico -->
                          <div class="plan pBasic">
                            <div class="titlePlans titlePlanBasic">
                              <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappAnual[0]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                                  ${{valorMensualBasicoWhatsapp}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappAnual[0]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-basic-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'whatsapp', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Estandar -->
                          <div class="plan pAdvanced">
                            <div class="titlePlans titlePlanAdvanced">
                              <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappAnual[1]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                                  ${{valorMensualEstandarWhatsapp}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappAnual[1]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-advanced-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'whatsapp', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
      
                          <!-- Plan Premium -->
                          <div class="plan pEnterprise">
                            <div class="titlePlans titlePlanEnterprise">
                              <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                            </div>
                            <div class="container">
                              <p class="text-center pricePlan"><b>USD
                                  ${{formatPrecio(planesCanalWhatsappAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                                |
                                translate}}</p>
                              <hr class="bg-dark w-100 m-auto" style="height: 2px" />
      
                              <div class="info-plan" style="display: grid;">
                                <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                                  {{formatPrecio(planesCanalWhatsappAnual[2]?.cantidadIncluida)}}
                                  {{'contenidoMain.sectionPlanes.mensajes' | translate}} </p>
                                <p>{{'contenidoMain.sectionPlanes.descuento' | translate}} </p>
                                <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                                  ${{valorMensualPremiumWhatsapp}}</p>
                                <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                                  {{planesCanalWhatsappAnual[2]?.capacidadAlmacenamiento}}MB</p>
                              </div>
      
                              <button type="button" class="shadow-sm button-enterprise-outline"
                                (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'whatsapp', 'anual')">
                                {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                </div>
              </div>
              <!-- Planes Planes canal Whatsapp -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nati-sec-9">
      <div class="nati-content m-auto">
        <div class="d-flex flex-wrap flex-lg-nowrap gap-5 justify-content-center align-items-center p-2 p-lg-5">
          <div class="px-5">
            <h2 class="mt-5 mt-md-4">{{'contenidoMain.section10.titulo' | translate}}</h2>
            <p class="mb-0 my-md-4">{{'contenidoMain.section10.texto' | translate}}</p>
          </div>
          <div>
            <img src="../../../assets/images/landing/innovati-logo.webp" alt="Nati" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div mat-fab class="sticky-button scroll-to-top" *ngIf="pageYoffset > 0" (mouseenter)="showSticky = true" (mouseleave)="showSticky = false">
  <a href="https://app.nati.ai/a7f374d8-45c2-4eaa-8f04-9e7861914049" target="_blank">
    <img id="img-sticky-btn" src="../../../assets/images/landing/nati-buble.webp" alt="Nati" frameborder="0" width="100%" height="100%" allowfullscreen>
  </a>
  <div id="sticky-content" *ngIf="showSticky && !isMobile">
    <p id="textosticky">{{'contenidoMain.stickyButton.texto' | translate}}</p>
  </div>
</div>


<button type="button" class="scroll-to-top left-position" (click)="scrollToTop()" *ngIf="pageYoffset > 0">
  <span class="arrow up"></span>
</button>