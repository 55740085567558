<div class="modal-body">
    <div class="register-container">
        <div class="row m-0 row-container">
            <div class="col d-block m-auto d-none d-xl-block">
                <div class="w-100 bg-nati-avatar">
                    <img src="../../../../../assets/images/logo.png" class="position-absolute top-0 m-3" alt="Nati" width="155">

                    <div class="text-center">
                        <img src="../../../assets/images/landing/nati.webp" alt="Nati avatar" class="img-fluid avatar">
                    </div>

                    <div class="blur-container">
                        <div class="blur-text">
                            <h5>Plataforma Nati de <br><b>Personas digitales</b></h5>
                            <p class="mt-3">Inteligencia artificial para automatizar las conversaciones con los clientes.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="w-75 d-block m-auto text-center">

                    <h4 class="text-center fw-bold">Aviso:</h4>
                    <h4 class="text-center fw-bold">Te recomedamos adquirir un plan</h4>

                    <hr class="line-unavailable">

                    <p class="service-unavailable-text">En este momento no cuentas con un plan activo.</p>
                    <p class="service-unavailable-text">Sin embargo puedes ingresar al panel de administración para ver tu historial de pagos o sacar copia de una factura.</p>
                    <p class="service-unavailable-text">En este momento ninguno de los flujos que tenias están funcionando.  Te recomendamos adquirir un plan para que tus flujos vuelvan a estar disponibles.</p>

                    <hr class="line-unavailable">

                    <div class="d-flex gap-3 justify-content-center">
                      <button type="button" class="button-primary-dashboard" (click)="closeModal()">Ir al panel de administración</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
