import { Component, ElementRef, ViewChild, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FacturacionService } from '../../../../services/facturacion/facturacion.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CrearFuentePagoService } from 'src/app/services/facturacion/crear-fuente-pago.service'
import { CrearFuentePago } from 'src/app/models/facturacion/crear-fuente-pago.model';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ObtenerPlanesService } from 'src/app/services/facturacion/obtener-planes.service';
import { Planes } from 'src/app/models/facturacion/obtener-planes.model';
import { CrearSuscripcionService } from 'src/app/services/facturacion/crear-suscripcion.service';
import { CrearSuscripcion } from 'src/app/models/facturacion/crear-suscripcion.model';
import { TranslateService } from '@ngx-translate/core';
import { ObtenerSuscripcionesActivasService } from 'src/app/services/facturacion/obtener-suscripciones-activas.service';
import { SuscripcionesActivas } from 'src/app/models/facturacion/obtener-suscripciones-activas.model';
import { PermisosDto } from 'src/app/models/dashboard/usuarios/permisos.model';
import { PermisosService } from 'src/app/services/dashboard/usuarios/permisos.service';

@Component({
  selector: 'app-modal-agregar-metodopago',
  templateUrl: './modal-agregar-metodopago.component.html',
  styleUrls: ['./modal-agregar-metodopago.component.scss'],
})
export class ModalAgregarMetodopagoComponent implements OnInit, OnDestroy {
  @Output() modalClosed = new EventEmitter();

  planSelected: string = '';
  planes: Planes;
  planSelectedID: string = '';

  //Dashboard Service Tenant Info
  tenantInfo$: Subscription;
  dataService: any;

  //Tenant
  tenant: string;
  emailTenant: string;

  isLoading: boolean = false;

  @ViewChild('cardNumberInput') cardNumberInput: ElementRef;
  @ViewChild('cvcInput') cvcInput: ElementRef;
  cardToken: any;

  acceptanceToken: string;
  acceptedTerms: boolean = false;
  paymentForm: FormGroup;
  selectedMonth: any;
  selectedYear: any;

  idUserB2C: string;
  permisosData: PermisosDto = {
    IdPermisos: '',
    IdUserB2C: '',
    Vistas: {
      Web: true,
      Kiosko: true,
      Narradora: true,
      Whatsapp: true,
      Llamadas: true,
      Usuarios: true,
      Facturacion: true,
      Suscripcion: true,
      Estadisticas: true,
      SubscriptionKey: true
    }
  }

  planSuscripcionActual: any;

  months: any[] = [
    { value: '01', label: 'Enero' },
    { value: '02', label: 'Febrero' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Mayo' },
    { value: '06', label: 'Junio' },
    { value: '07', label: 'Julio' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ];

  years: any[] = [];

  constructor(private wompiService: FacturacionService,
    private fb: FormBuilder,
    private crearFuentePagoService: CrearFuentePagoService,
    private dashboardService: DashboardService,
    private modalRef: BsModalRef,
    private obtenerPlanesService: ObtenerPlanesService,
    private crearSuscripcionService: CrearSuscripcionService,
    private translate: TranslateService,
    private obtenerSuscripcionesActivasService: ObtenerSuscripcionesActivasService,
    private permisosService: PermisosService) {

    this.paymentForm = this.fb.group({
      cardHolder: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(5), Validators.pattern(/^[\p{L}\s'.-]+$/u), Validators.pattern(/[^\s]+/)]],
      cardNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(5), Validators.pattern(/^\S.*$/)]],
      cvc: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(3), Validators.pattern(/^\S.*$/)]],
      expiryMonth: [null, Validators.required],
      expiryYear: [null, Validators.required],
      acceptedTerms: [false, Validators.requiredTrue],
    });

    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 20; i++) {
      const year = currentYear + i;
      this.years.push({ value: year, label: year.toString() });
    }
  }

  async ngOnInit() {
    try {
      //Get the tenant information from the dashboard service and save the tenant name
      this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
        next: (data: TenantInfo) => {
          if (data.haveTenant) {
            this.dataService = data;
            this.tenant = this.dataService.tenantName;
            this.emailTenant = this.dataService.dataB2C.emails[0];
            this.idUserB2C = this.dataService.dataB2C.oid;
            this.obtenerPermisos();
          } else if (!data.haveTenant) {
            return;
          }
        }, error: (error) => {
          console.log(error);
        }
      });

      this.acceptanceToken = await this.wompiService.getAcceptanceToken();
      console.log('Token de Aceptación:', this.acceptanceToken);
    } catch (error) {
      console.error('Error al obtener el Token de Aceptación:', error);
    }
    this.setupInputListeners();

    this.planSelected = this.modalRef.content.planSelected || '';

    this.obtenerPlanesService.obtenerPlanes().subscribe({
      next: (data: Planes) => {
        this.planes = data;
        console.log('Planes:', this.planes);

        this.planSelectedID = this.planes.data.find((plan) => plan.idPlan === this.planSelected).idPlan;
      }
    });
  }

  filterMonths(): void {
    if (!this.selectedYear?.value) return;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const allMonths = [
      { value: '01', label: 'Enero' },
      { value: '02', label: 'Febrero' },
      { value: '03', label: 'Marzo' },
      { value: '04', label: 'Abril' },
      { value: '05', label: 'Mayo' },
      { value: '06', label: 'Junio' },
      { value: '07', label: 'Julio' },
      { value: '08', label: 'Agosto' },
      { value: '09', label: 'Septiembre' },
      { value: '10', label: 'Octubre' },
      { value: '11', label: 'Noviembre' },
      { value: '12', label: 'Diciembre' },
    ];

    if (parseInt(this.selectedYear.value) > currentYear) {
      this.months = allMonths;
    } else if (parseInt(this.selectedYear.value) === currentYear) {
      this.months = allMonths.filter(month => parseInt(month.value) >= currentMonth);
    } else {
      this.months = [];
    }
  }


  setupInputListeners(): void {
    this.cardNumberInput.nativeElement.addEventListener('input', (event: any) => {
      const inputValue = event.target.value;
      const numericValue = inputValue.replace(/[^0-9]/g, '');
      event.target.value = numericValue;
    });

    this.cvcInput.nativeElement.addEventListener('input', (event: any) => {
      const inputValue = event.target.value;
      const numericValue = inputValue.replace(/[^0-9]/g, '');
      event.target.value = numericValue;
    });

    this.paymentForm.get('expiryYear').valueChanges.subscribe((year) => {
      this.selectedYear = year;
      this.filterMonths();
    });
  }

  async onSubmit() {
    if (this.paymentForm.invalid) {
      console.log("valido");
      return;
    }
    this.isLoading = true;

    const body = {
      number: this.paymentForm.value.cardNumber,
      exp_month: this.paymentForm.value.expiryMonth.value,
      exp_year: this.paymentForm.value.expiryYear.label.substr(-2),
      cvc: this.paymentForm.value.cvc,
      card_holder: this.paymentForm.value.cardHolder,
    }

    this.wompiService.getToken(body).subscribe(
      (response) => {
        console.log('Respuesta del servicio:', response);
        this.cardToken = response.data.id;

        const metododepago: CrearFuentePago = {
          tenant: this.tenant,
          cardTokenId: this.cardToken,
          acceptanceToken: this.acceptanceToken,
          emailTenant: this.emailTenant,
          predeterminado: true,
        };

        this.crearFuentePagoService.crearFuentePago(metododepago).subscribe({
          next: async (data: CrearFuentePago) => {
            console.log('Fuente de pago creada', data);

            console.log('Creando suscripcion');
            const suscripcion: CrearSuscripcion = {
              idPlan: this.planSelected,
              tenant: this.tenant
            };

            await this.crearSuscripcion(suscripcion);
            console.log('Suscripcion creada');

            this.modalRef.hide();
            this.translate.get('contenidoDashboard.facturacion.metododepago.confirmaciones.agregadaTitulo').subscribe((translatedTitle: string) => {
              this.translate.get('contenidoDashboard.facturacion.metododepago.confirmaciones.agregadaTexto').subscribe((translatedText: string) => {
                Swal.fire({
                  customClass: {
                    confirmButton: 'swalConfirmBtn',
                    cancelButton: 'swalCancelBtn'
                  },
                  title: translatedTitle,
                  text: translatedText,
                  icon: 'success',
                  iconColor: '#0E2359',
                  confirmButtonText: 'Aceptar',
                }).then(() => {
                  window.location.reload();
                });
              });
            });


            this.paymentForm.reset();
            this.isLoading = false;

          }, error: (error) => {
            console.error('Error al procesar la tarjeta:', error);
            this.translate.get('contenidoDashboard.facturacion.metododepago.confirmaciones.errorTexto').subscribe((translatedTexto: string) => {
              Swal.fire({
                customClass: {
                  confirmButton: 'swalConfirmBtn',
                  cancelButton: 'swalCancelBtn'
                },
                text: translatedTexto,
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            });

            this.isLoading = false;
          }
        });

      },
      (error) => {
        if (error.status == 422) {
          if (error.error.error.type.includes("INPUT_VALIDATION_ERROR")) {
            Swal.fire({
              customClass: {
                confirmButton: 'swalConfirmBtn',
                cancelButton: 'swalCancelBtn'
              },
              title: "Error al tokenizar la tarjeta",
              text: "Verifica que los datos ingresados correspondan con los de tu tarjeta",
              icon: 'error',
              confirmButtonText: "Aceptar",
            });
            this.isLoading = false;
          } else {
            Swal.fire({
              customClass: {
                confirmButton: 'swalConfirmBtn',
                cancelButton: 'swalCancelBtn'
              },
              title: 'Error al tokenizar la tarjeta',
              text: 'Ocurrio un error desconocido contacta al administrador',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
            this.isLoading = false;
          }
        }
        console.error('Error al llamar al servicio:', error);
      }
    );

  }

  async crearSuscripcion(suscripcion: CrearSuscripcion) {
    this.crearSuscripcionService.crearSuscripcion(suscripcion).subscribe({
      next: (data: CrearSuscripcion) => {
        console.log(data);
        console.log('Suscripcion creada correctamente');
        this.obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(this.tenant).subscribe({
          next: (data: SuscripcionesActivas) => {
            console.log('Consulta de suscripciones activas');
            // Search if the user have an active subscription for the canal selected and the idPlan selected
            // const suscripcionActiva = data.data.find((suscripcion) => suscripcion.idPlan === this.planSelected);
            for (const suscripcion of data.data) {
              this.planSuscripcionActual = this.planes.data.find((plan) => plan.idPlan === suscripcion.idPlan);
            }

            // const suscripcionActiva = data.data.find((suscripcion) => suscripcion.canal === canal && suscripcion.idPlan === planSelected.idPlan);
            console.log('Suscripcion activa', this.planSuscripcionActual.titulo);

            if (this.planSuscripcionActual.canal == 'kiosco') {
              this.planSuscripcionActual.canal = 'kiosko'
            }
            if (this.planSuscripcionActual.canal) {
              const canalActivo = this.planSuscripcionActual.canal;
              const permisosPorCanal = {
                'web': {
                  web: true,
                },
                'kiosko': {
                  kiosko: true,
                },
                'whatsapp': {
                  whatsapp: true,
                },
              };

              console.log('Permisos actuales:', this.permisosData.Vistas);
              console.log('Permisos del canal activo:', permisosPorCanal[canalActivo]);

              const nuevosPermisos = {
                ...this.permisosData.Vistas,
                ...permisosPorCanal[canalActivo],
              };

              console.log('Nuevos permisos fusionados:', nuevosPermisos);

              // Actualizar solo el permiso de vistas
              this.permisosData.Vistas = nuevosPermisos;

              this.permisosService.actualizarPermisos(this.permisosData).subscribe({
                next: (dataPermisos) => {
                  console.log('se actualizaron los permisos en agregar metodo de pago', dataPermisos);

                }, error: (error) => {
                  console.log(error);
                }
              });
              console.log('permisos actualizados', this.permisosData)

            } else {
              console.log("No tiene suscripción, no se crean permisos")
            }
          }, error: (error) => {
            console.log(error);
          }
        });
        this.modalClosed.emit();
        // window.location.reload();
      }, error: (error) => {
        console.error(error);
        console.log('Error al crear la suscripcion');
      }
    });
  }

  obtenerPermisos() {
    this.permisosService.obtenerPermisos(this.idUserB2C).subscribe(
      response => {
        console.log('Permisos obtenidos:', response);
        this.permisosData = response.data;
      },
      error => {
        console.error('Error al obtener permisos:', error);

      }
    );
  }

  toggleCheckbox() {
    this.acceptedTerms = !this.acceptedTerms;
  }

  ngOnDestroy(): void {
    this.tenantInfo$.unsubscribe();
  }

}




