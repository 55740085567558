import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { firstValueFrom } from 'rxjs';

import { Carousel } from 'src/app/models/carousel/carousel.model'

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  constructor(private http: HttpClient) {

  }

  async getCarousel(Lang: string) {
    const res = await firstValueFrom(this.http.get<any>(`assets/i18n/${Lang}.json`));
    const data = <Carousel[]>res.data;
    const dataBlog = <Carousel[]>res.dataBlog;
    const dataUseCases = <Carousel[]>res.dataUseCases;
    return { data, dataBlog, dataUseCases };
  }
}
