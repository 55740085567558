import { Component, HostListener, OnInit, Inject, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ElementRef, Renderer2 } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult } from '@azure/msal-browser';
import { b2cPolicies } from '../../../auth-config';

import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';

import { ObtenerPlanesService } from 'src/app/services/facturacion/obtener-planes.service';
import { Planes } from 'src/app/models/facturacion/obtener-planes.model';

import { ObtenerSuscripcionesActivasService } from 'src/app/services/facturacion/obtener-suscripciones-activas.service';
import { SuscripcionesActivas } from 'src/app/models/facturacion/obtener-suscripciones-activas.model';

import { ObtenerSuscripcionesTenantService } from 'src/app/services/facturacion/obtener-suscripciones-tenant.service';
import { SuscripcionesTenant } from 'src/app/models/facturacion/obtener-suscripciones-tenant.model';

import { CrearSuscripcionService } from 'src/app/services/facturacion/crear-suscripcion.service';
import { CrearSuscripcion } from 'src/app/models/facturacion/crear-suscripcion.model';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalServicioFinalizadoComponent } from '../../dashboard/shared-dashboard/modal-servicio-finalizado/modal-servicio-finalizado.component';
import { ModalServicioGratisFinalizadoComponent } from '../../dashboard/shared-dashboard/modal-servicio-gratis-finalizado/modal-servicio-gratis-finalizado.component';
import { ModalSinPlanSeleccionadoComponent } from '../../dashboard/shared-dashboard/modal-sin-plan-seleccionado/modal-sin-plan-seleccionado.component';
import { ModalInformacionClienteComponent } from '../../dashboard/shared-dashboard/modal-informacion-cliente/modal-informacion-cliente.component';

import { ClienteService } from 'src/app/services/facturacion/cliente.service';
import { ClienteBasico } from 'src/app/models/facturacion/cliente.model';

import { NotificacionesService } from 'src/app/services/notificaciones/notificaciones.service';
import { Notificaciones } from 'src/app/models/notificaciones/notificaciones.model';
import { PermisosService } from 'src/app/services/dashboard/usuarios/permisos.service';
import { PermisosDto } from 'src/app/models/dashboard/usuarios/permisos.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() mostrarNotificacionesEvent = new EventEmitter<boolean>();
  enviarNotificaciones: Notificaciones = {
    statusCode: 200,
    data: [],
    message: 'Notificaciones'
  };
  mostrarNotificaciones = false;
  private dateNow = new Date();

  modalRef?: BsModalRef;
  modalRef2?: BsModalRef;
  modalRef3?: BsModalRef;
  notifiedPlanUnavailable: boolean = false;

  loginDisplay: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  UrlBaseB2C = 'https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/';
  ScopeApiRead = 'Api.Read';

  //Dashboard Service Tenant Info
  tenantInfo$: Subscription;
  dataService: any;

  // Tenant del usuario
  tenant: string;
  konectaTenant: boolean = false;
  telefono: string;
  correoTenant: string;

  nickname: string;
  given_name: any;
  family_name: any;
  tenant_name: any;

  avatarUrl: string;

  pageYoffset = 0;

  planes: Planes;
  haveQueryParams: boolean = false;

  permisosData: PermisosDto = {
    IdPermisos: '',
    IdUserB2C: '',
    Vistas: {
      Web: false,
      Kiosko: false,
      Narradora: false,
      Whatsapp: false,
      Llamadas: false,
      Usuarios: true,
      Facturacion: true,
      Suscripcion: true,
      Estadisticas: true,
      SubscriptionKey: true
    }
  }

  siteScrolled = false;
  @HostListener('window:scroll', ['$event']) onScroll() {
    this.pageYoffset = window.pageYOffset;
    this.siteScrolled = this.pageYoffset > 50;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private dashboardService: DashboardService,
    private obtenerPlanesService: ObtenerPlanesService,
    private obtenerSuscripcionesActivasService: ObtenerSuscripcionesActivasService,
    private obtenerSuscripcionesTenantService: ObtenerSuscripcionesTenantService,
    private crearSuscripcionService: CrearSuscripcionService,
    private modalService: BsModalService,
    private clienteService: ClienteService,
    private el: ElementRef,
    private renderer: Renderer2,
    private notificacionesService: NotificacionesService,
    private permisosService: PermisosService
  ) { }

  ngOnInit() {
    this.obtenerPlanes();

    //Get the tenant information from the dashboard service and save the tenant name
    this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
      next: (data: TenantInfo) => {
        if (data.haveTenant) {
          this.dataService = data;
          this.tenant = this.dataService.tenantName;
          this.telefono = this.dataService.dataB2C.extension_Celular;
          this.correoTenant = this.dataService.dataB2C.emails[0];
          if (this.tenant === 'konecta') {
            this.konectaTenant = true;
          } else {
            this.konectaTenant = false;
          }

          this.getNotificaciones();
        } else if (!data.haveTenant) {
          return;
        }
      }, error: (error) => {
        console.log(error);
      }
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;

        const idToken = payload.accessToken;
        localStorage.setItem('token', idToken);

        const tenantName = payload.idTokenClaims['extension_Empresa'];
        const idUserB2C = payload.idTokenClaims['oid'];
        const state = payload.state;

        if (state) {
          this.haveQueryParams = true;
          const queryParams = JSON.parse(payload.state);

          if (queryParams.canal && queryParams.plan) {
            if (this.planes) {
              const planSelected = this.planes.data.find((plan) => plan.canal === queryParams.canal && plan.titulo === queryParams.plan && plan.tipoFrecuencia === queryParams.frecuencia);

              let suscripcion: CrearSuscripcion = {
                idPlan: planSelected.idPlan,
                tenant: tenantName,
              }

              let canal = planSelected.canal;
              sessionStorage.setItem('canal-initial-configuration', canal);

              this.authService.instance.setActiveAccount(payload.account);

              this.obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(tenantName).subscribe({
                next: (data: SuscripcionesActivas) => {
                  const suscripcionActiva = data.data.find((suscripcion) => suscripcion.canal === canal && suscripcion.idPlan === planSelected.idPlan);

                  if (suscripcionActiva) {
                    this.router.navigate([`/dashboard/${canal}`]);
                  } else {
                    switch (planSelected.titulo) {
                      case 'prueba-gratis':
                        this.permisosData.Vistas.Web = true;
                        this.obtenerInfoCliente(suscripcion);
                        this.agregarPermisos(idUserB2C, true);
                        break;
                      case 'basico':
                        this.openModalInfoTributaria(planSelected.idPlan);
                        this.agregarPermisos(idUserB2C, false);
                        break;
                      case 'estandar':
                        this.openModalInfoTributaria(planSelected.idPlan);
                        this.agregarPermisos(idUserB2C, false);
                        break;
                      case 'premium':
                        this.openModalInfoTributaria(planSelected.idPlan);
                        this.agregarPermisos(idUserB2C, false);
                        break;
                      default:
                        this.agregarPermisos(idUserB2C, false);
                        break;
                    }
                  }
                }, error: (error) => {
                  console.log(error);
                }
              });
            }
          }
        } else {
          this.authService.instance.setActiveAccount(payload.account);
          this.obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(tenantName).subscribe({
            next: (data: SuscripcionesActivas) => {
              if (data.data && data.data.length > 0) {
                const lastSubscription = data.data[0];

                this.router.navigate([`/dashboard/${lastSubscription.canal}`]);
              } else {

                this.router.navigate(['/dashboard/suscripcion']);
              }
            }, error: (error) => {
              console.log(error);
            }
          });
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

        const accInfo = this.authService.instance.getActiveAccount()?.idTokenClaims;
        if (accInfo) {
          this.nickname = accInfo['name'];
          this.given_name = accInfo['given_name'];
          this.family_name = accInfo['family_name'];
          this.tenant_name = accInfo['extension_Empresa'];

          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.checkActiveSubscriptions();
              this.closeProfile();
            }
          });


          let url: string;
          this.router.events
            .pipe(
              filter(e => e instanceof NavigationEnd)
            )
            .subscribe((navEnd: NavigationEnd) => {
              url = navEnd.urlAfterRedirects;

              if (url) {
                this.router.navigateByUrl(url);
              }
            });

          setTimeout(() => {
            if (!url) {
              this.router.navigateByUrl('/dashboard');
            }
          }, 1000);
        }

      })

    this.renderer.listen('document', 'click', (event) => {
      const isProfileClicked = this.el.nativeElement.contains(event.target);
      const isProfileDropdownClicked = this.el.nativeElement.querySelector('ul').contains(event.target);

      if (!isProfileClicked && !isProfileDropdownClicked) {
        this.closeProfile();
      }
    });

  }

  get currentUrl(): string {
    return this.router.url;
  }

  toggleNotificaciones() {
    this.mostrarNotificaciones = !this.mostrarNotificaciones;

    if (this.mostrarNotificaciones) {
      this.getNotificaciones();
    }

    this.mostrarNotificacionesEvent.emit(this.mostrarNotificaciones);
  }

  getNotificaciones() {
    this.notificacionesService.getNotificaciones(this.tenant).subscribe({
      next: (resp: Notificaciones) => {
        this.enviarNotificaciones = resp;

        this.enviarNotificaciones.data.forEach(element => {
          let fechaCreacion = new Date(element.fechaCreacion);

          fechaCreacion.setHours(fechaCreacion.getHours() + element.tiempoVida);
          // console.log('Fecha creación + tiempo de vida: ', fechaCreacion);

          if (fechaCreacion <= this.dateNow) {
            // console.log('Eliminar notificación');
            this.enviarNotificaciones.data.splice(this.enviarNotificaciones.data.indexOf(element), 1);
          }
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  closeProfile() {
    const checkbox = this.el.nativeElement.querySelector('#profile');
    if (checkbox.checked) {
      checkbox.checked = false;
    }

    this.mostrarNotificaciones = false;
  }

  obtenerPlanes() {
    this.obtenerPlanesService.obtenerPlanes().subscribe({
      next: (data: Planes) => {
        this.planes = data;
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  obtenerInfoCliente(suscripcion: CrearSuscripcion) {
    this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
      next: (data: TenantInfo) => {
        if (data.haveTenant) {
          this.dataService = data;
          this.tenant = this.dataService.tenantName;
          this.telefono = this.dataService.dataB2C.extension_Celular;
          this.correoTenant = this.dataService.dataB2C.emails[0];
          this.crearCliente(suscripcion);
          if (this.tenant === 'konecta') {
            this.konectaTenant = true;
          } else {
            this.konectaTenant = false;
          }
        } else if (!data.haveTenant) {
          return;
        }
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  crearCliente(suscripcion: CrearSuscripcion) {
    const cliente: ClienteBasico = {
      tenant: this.tenant,
      telefonoTenant: this.telefono,
      correoTenant: this.correoTenant
    }

    this.clienteService.crearClienteBasico(cliente).subscribe({
      next: () => {
        this.crearSuscripcion(suscripcion);
      }, error: (error) => {
        console.error(error);
      }
    });
  }

  agregarPermisos(idUserB2C: string, pruebaGratis: boolean) {
    this.permisosService.obtenerPermisos(idUserB2C).subscribe({
      error: (_error) => {
        this.permisosData.IdUserB2C = idUserB2C;
        this.permisosService.agregarPermisos(this.permisosData).subscribe({
          next: _response => {
            if (pruebaGratis) {
              this.router.navigate(['/dashboard/initial-configuration']);

              setTimeout(() => {
                this.dashboardService.setQueryPermissions(true);
              }, 1000);
            } else {
              this.router.navigate(['/dashboard/initial-configuration']).then(() => {
                this.haveQueryParams = false;
              });
            }
          }, error: error => {
            console.error('Error al actualizar permisos 1:', error);
          }}
        );
      }
    });
  }


  crearSuscripcion(suscripcion: CrearSuscripcion) {
    this.crearSuscripcionService.crearSuscripcion(suscripcion).subscribe({
      error: (error) => {
        console.error(error);
      }
    });
  }

  checkActiveSubscriptions() {
    if (this.loginDisplay) {
      if (this.haveQueryParams === false) {
        this.obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(this.tenant_name).subscribe({
          next: (res: SuscripcionesActivas) => {
            if (res.data && res.data.length > 0) {
              const dateEnd = res.data[0].fechaFin === null ? null : new Date(res.data[0].fechaFin);
              const dateNow = new Date();

              if (dateEnd !== null && dateEnd <= dateNow) {
                this.checkPreviousSubscriptions();
              }
            } else {
              this.checkPreviousSubscriptions();
            }
          }, error: (err) => {
            console.log(err);
          }
        });
      }
    }
  }

  checkPreviousSubscriptions() {
    this.obtenerSuscripcionesTenantService.obtenerSuscripcionesTenant(this.tenant_name).subscribe({
      next: (res: SuscripcionesTenant) => {
        if (res.data && res.data.length > 0) {
          const freeTrial = res.data[0].tituloPlan.includes('prueba-gratis') ? true : false;

          if (freeTrial) {
            this.openModalPlanFreeUnavailable();
          } else {
            if (!this.notifiedPlanUnavailable) {
              this.openModalPlanUnavailable();
            }

            this.notifiedPlanUnavailable = true;
          }
        } else {
          this.openModalNoPlanSelected();
        }
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  openModalInfoTributaria(planSelected: string) {
    const initialState = {
      planSelected: planSelected
    };

    this.modalRef = this.modalService.show(ModalInformacionClienteComponent, {
      initialState,
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalPlanUnavailable() {
    this.modalRef = this.modalService.show(ModalServicioFinalizadoComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalPlanFreeUnavailable() {
    this.modalRef2 = this.modalService.show(ModalServicioGratisFinalizadoComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalNoPlanSelected() {
    this.modalRef3 = this.modalService.show(ModalSinPlanSeleccionadoComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }


  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
      const languageCode = localStorage['language'];
      const loginRedirectRequest = {
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
        extraQueryParameters: {
          ui_locales: languageCode
        }
      } as RedirectRequest;

      this.authService.loginRedirect(loginRedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  signup() {
    const languageCode = localStorage['language'];
    const signup = {
      scopes: [this.UrlBaseB2C + this.ScopeApiRead],
      authority: b2cPolicies.authorities.signUp.authority,
      extraQueryParameters: {
        ui_locales: languageCode
      }
    };

    this.login(signup);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  logout() {
    this.authService.logoutRedirect();
    localStorage.removeItem('token');
  }
}
