import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

//Servicio consulta persona digital
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ObtenerSuscripcionesActivasService } from 'src/app/services/facturacion/obtener-suscripciones-activas.service';
import { SuscripcionesActivas } from 'src/app/models/facturacion/obtener-suscripciones-activas.model';
import { Subscription, forkJoin } from 'rxjs';

import { PermisosService } from 'src/app/services/dashboard/usuarios/permisos.service';
import { CasosDeUsoService } from 'src/app/services/dashboard/shared-services/casosdeuso/casosdeuso.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  modalRef?: BsModalRef;

  valid: boolean = false;
  btnToggle: boolean;

  //Data B2C
  loginDisplay = false;
  nombre: string;
  tenant: string;

  idAvatar: string;

  //Interface TenantInfo
  tenantInfo: TenantInfo

  //Dashboard Service Tenant Info
  tenantInfo$: Subscription;
  dataService: any;

  // Tenant del usuario
  konectaTenant: boolean = false;
  telefono: string;
  correoTenant: string;;

  suscripcionPlanCanalWeb: any;
  suscripcionPlanCanalWebActiva: boolean = false;
  suscripcionPlanCanalKiosco: any;
  suscripcionPlanCanalKioscoActiva: boolean = false;
  suscripcionPlanCanalWhatsapp: any;
  suscripcionPlanCanalWhatsappActiva: boolean = false;
  suscripcionPlanCanalNarradora: any;
  suscripcionPlanCanalNarradoraActiva: boolean = false;
  estadoSuscripcion: boolean = false;

  //permisos
  permisosWeb: boolean;
  permisosWhatsapp: boolean;
  permisosKiosco: boolean;
  permisosNarradora: boolean;
  permisosUsuarios: boolean;
  permisosFactura: boolean;
  permisosSuscripcion: boolean;
  permisosEstadisticas: boolean;
  permisosSubscriptionKey: boolean;

  constructor(
    private renderer: Renderer2,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private dashboardService: DashboardService,
    private obtenerSuscripcionesActivasService: ObtenerSuscripcionesActivasService,
    private permisosService: PermisosService,
    private casosDeUsoService: CasosDeUsoService
  ) {
    this.btnToggle = true;
  }

  @ViewChild('spinnerLoading', { static: true }) spinnerLoading!: ElementRef;
  @ViewChild('shrinkBtn', { static: true }) shrinkBtn: ElementRef;
  @ViewChild('sidebarLinks', { static: true }) sidebarLinks: ElementRef;
  @ViewChild('section', { static: true }) section: ElementRef;
  @ViewChild('validationDp', { static: true }) validationDp!: ElementRef;

  ngOnInit(): void {
    this.renderer.addClass(this.sidebarLinks.nativeElement, 'disabled');
    this.renderer.setStyle(this.validationDp.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.spinnerLoading.nativeElement, 'display', 'block');

    //Get the tenant information from the dashboard service and save the tenant name
    this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
      next: (data: TenantInfo) => {
        if (data.haveTenant) {
          this.dataService = data;
          this.tenant = this.dataService.tenantName;
          this.telefono = this.dataService.dataB2C.extension_Celular;
          this.correoTenant = this.dataService.dataB2C.emails[0];
          this.obtenerPermisos(this.dataService.dataB2C.oid)
          if (this.tenant === 'konecta') {
            this.konectaTenant = true;
          } else {
            this.konectaTenant = false;
          }

          this.obtenerSuscripcionesActivas();
        } else if (!data.haveTenant) {
          return;
        }
      }, error: (error) => {
        console.log(error);
      }
    });

    this.dashboardService.getSidebarLinksState().subscribe({
      next: (state: boolean) => {
        this.setSidebarLinksStyle(state);
      }
    });

    this.dashboardService.getQueryPermissions().subscribe({
      next: () => {
        this.obtenerSuscripcionesActivas();
        this.obtenerPermisos(this.dataService.dataB2C.oid || '');
      }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);

        let canales = ['web', 'kiosco', 'whatsapp', 'narradora'];

        let casosDeUsoCanales = canales.map((canal) => {
          return this.casosDeUsoService.consultaCasosDeUso(this.tenant, canal);
        });

        forkJoin(casosDeUsoCanales).subscribe({
          next: (data: any) => {
            data = data.map((item: any, index: number) => {
              return {
                canal: canales[index],
                data: item.data
              };
            });

            let haveUseCase = data.map((item: any) => {
              return item.data.length > 0;
            });

            if (haveUseCase.includes(true)) {
              const tenantInfo = {
                data: 'Tiene casos de uso',
                dataB2C: this.authService.instance.getActiveAccount()?.idTokenClaims,
                haveTenant: true,
                tenantName: this.tenant,
                loading: false
              };
              
              this.tenantInfo = tenantInfo;
              this.dashboardService.setTenantInfo(tenantInfo);

              setTimeout(() => {
                this.setSidebarLinksStyle(true);
                this.renderer.setStyle(this.spinnerLoading.nativeElement, 'display', 'none');
                this.renderer.setStyle(this.validationDp.nativeElement, 'display', 'block');
              }, 1000);
            } else {
              const tenantInfo = {
                data: 'No tiene casos de uso',
                dataB2C: this.authService.instance.getActiveAccount()?.idTokenClaims,
                haveTenant: true,
                tenantName: this.tenant,
                loading: false
              };

              this.tenantInfo = tenantInfo;
              this.dashboardService.setTenantInfo(this.tenantInfo);

              this.setSidebarLinksStyle(true);
              this.renderer.setStyle(this.spinnerLoading.nativeElement, 'display', 'none');
              this.renderer.setStyle(this.validationDp.nativeElement, 'display', 'block');
            }
          }, error: (error: any) => {
            console.log(error);
          }
        });
      });

    //Ocultar - mostrar sidebar
    this.renderer.listen(this.shrinkBtn.nativeElement, 'click', () => {
      this.btnToggle = !this.btnToggle;

      if (this.btnToggle) {
        this.renderer.addClass(this.section.nativeElement, 'shrink');
      } else {
        this.renderer.removeClass(this.section.nativeElement, 'shrink');
      }
    
      const btnTooltipSidebar = Array.from(document.getElementsByClassName('tooltip-custom-sidebar'));
      for (const tooltipElement of btnTooltipSidebar) {
        if (this.btnToggle) {
          this.renderer.removeClass(tooltipElement, 'd-none');
        } else {
          this.renderer.addClass(tooltipElement, 'd-none');
        }
      }
    
      this.renderer.addClass(this.section.nativeElement, 'hovered');
      setTimeout(() => {
        this.renderer.removeClass(this.section.nativeElement, 'hovered');
      }, 500);
    });    
  }

  setSidebarLinksStyle(state: boolean) {
    if (state) {
      this.renderer.removeClass(this.sidebarLinks.nativeElement, 'disabled');
    } else {
      this.renderer.addClass(this.sidebarLinks.nativeElement, 'disabled');
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this.nombre = claims.name;
    this.tenant = claims.extension_Empresa;
  }

  logout() {
    this.authService.logoutRedirect();
    localStorage.removeItem('token');
  }

  obtenerSuscripcionesActivas() {
    this.obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(this.tenant).subscribe({
      next: (response: SuscripcionesActivas) => {
        const suscripcionCanalWeb = response.data.filter((suscripcion: any) => suscripcion.canal === 'web');
        this.suscripcionPlanCanalWeb = suscripcionCanalWeb[0] || {};
        this.suscripcionPlanCanalWebActiva = this.suscripcionPlanCanalWeb.estado === 'activa' || false;

        const suscripcionCanalKiosco = response.data.filter((suscripcion: any) => suscripcion.canal === 'kiosco');
        this.suscripcionPlanCanalKiosco = suscripcionCanalKiosco[0] || {};
        this.suscripcionPlanCanalKioscoActiva = this.suscripcionPlanCanalKiosco.estado === 'activa' || false;

        const suscripcionCanalWhatsapp = response.data.filter((suscripcion: any) => suscripcion.canal === 'whatsapp');
        this.suscripcionPlanCanalWhatsapp = suscripcionCanalWhatsapp[0] || {};
        this.suscripcionPlanCanalWhatsappActiva = this.suscripcionPlanCanalWhatsapp.estado === 'activa' || false;

        const suscripcionCanalNarradora = response.data.filter((suscripcion: any) => suscripcion.canal === 'narradora');
        this.suscripcionPlanCanalNarradora = suscripcionCanalNarradora[0] || {};
        this.suscripcionPlanCanalNarradoraActiva = this.suscripcionPlanCanalNarradora.estado === 'activa' || false;
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  obtenerPermisos(idUserB2C: string): void {
    this.permisosService.obtenerPermisos(idUserB2C).subscribe({
      next: (response: any) => {
        const responsePermisos = response.data;
        this.permisosWeb = responsePermisos.Vistas.Web;
        this.permisosWhatsapp = responsePermisos.Vistas.Whatsapp;
        this.permisosKiosco = responsePermisos.Vistas.Kiosko;
        this.permisosNarradora = responsePermisos.Vistas.Narradora; 
        this.permisosUsuarios = responsePermisos.Vistas.Usuarios;
        this.permisosFactura = responsePermisos.Vistas.Facturacion;
        this.permisosSuscripcion = responsePermisos.Vistas.Suscripcion;
        this.permisosEstadisticas = responsePermisos.Vistas.Estadisticas;
        this.permisosSubscriptionKey = responsePermisos.Vistas.SubscriptionKey
      }, error: (error) => {
        console.error('Error al obtener permisos:', error);
      }
    });
  }
}
