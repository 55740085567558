<div class="modal-body">
  <div class="register-container">
      <div class="row m-0 row-container">
          <div class="col d-block m-auto d-none d-xl-block">
              <div class="w-100 bg-nati-avatar">
                  <img src="../../../assets/images/logo.png" class="position-absolute top-0 m-3" alt="Nati" width="155" height="65">

                  <div class="text-center">
                      <img src="../../../assets/images/landing/nati.webp" alt="Nati avatar" class="img-fluid avatar">
                  </div>

                  <div class="blur-container">
                      <div class="blur-text">
                          <h5>{{'contenidoMain.modales.textImg1' | translate}}  <br><b>{{'contenidoMain.modales.textImg2' | translate}}</b></h5>
                          <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col">
              <div class="w-75 d-block m-auto text-center">

                  <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalFinalizado.texto1' | translate}}</h4>

                  <hr class="line-unavailable">

                  <p class="service-unavailable-text">{{'contenidoMain.modales.modalFinalizado.texto2' | translate}}</p>

                  <hr class="line-unavailable">

                  <div class="d-flex gap-3 justify-content-center">
                    <button type="button" class="button-primary-dashboard" (click)="openModalPlans()">{{'contenidoMain.modales.modalFinalizado.btonElegir' | translate}} <span class="arrow right"></span></button>

                    <button type="button" class="button-primary-dashboard" (click)="logout()">{{'contenidoMain.modales.modalFinalizado.cerrarSesion' | translate}}</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
