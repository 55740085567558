import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreacionCasoDeUso } from 'src/app/models/dashboard/shared-models/casosdeuso/crear-caso-de-uso.model';
import { EditarCasoUso } from 'src/app/models/dashboard/shared-models/casosdeuso/editar-caso-uso.model';
import { GenericResponse } from 'src/app/models/common/generic-response.model';
import { ObtenerCasoUso } from 'src/app/models/dashboard/shared-models/casosdeuso/obtener-caso-uso.model';
import { CasosDeUso } from 'src/app/models/dashboard/shared-models/casosdeuso/casos-de-uso.model';

@Injectable({
  providedIn: 'root'
})
export class CasosDeUsoService {

  private urlCasosDeUso = environment.urlCasosDeUso;

  private token = localStorage.getItem('token');
  private headers = { 'Authorization': 'Bearer ' + this.token };

  constructor(private http: HttpClient) { }

  public consultaCasoDeUso(id: string): Observable<GenericResponse<ObtenerCasoUso>> {
    return this.http.get<GenericResponse<ObtenerCasoUso>>(`${this.urlCasosDeUso}${id}`, { headers: this.headers });
  }

  public consultaCasosDeUso(tenant: string, canal: string): Observable<GenericResponse<CasosDeUso[]>> {
    return this.http.get<GenericResponse<CasosDeUso[]>>(`${this.urlCasosDeUso}tenant/${tenant}?canal=${canal}`, { headers: this.headers });
  }

  public crearCasoDeUso(casoDeUso: CreacionCasoDeUso): Observable<any> {
    return this.http.post<any>(this.urlCasosDeUso, casoDeUso, { headers: this.headers });
  }

  public editarCasoDeUso(id: string, casoDeUso: EditarCasoUso): Observable<void> {
    return this.http.patch<void>(`${this.urlCasosDeUso}${id}`, casoDeUso, { headers: this.headers });
  }

  public eliminarCasoDeUso(id: string): Observable<void> {
    return this.http.delete<void>(this.urlCasosDeUso + id, { headers: this.headers });
  }
}
